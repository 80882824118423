import '@ant-design/flowchart/dist/index.css';
import { Web3ReactProvider } from '@web3-react/core';
import 'antd/dist/antd.css';
import BigNumber from 'bignumber.js';
import React from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import { PersistGate } from 'redux-persist/integration/react';
import 'src/assets/scss/variable.scss';
import 'src/assets/scss/_themes.scss';
import './App.scss';
import Layout from './components/01.layout';
import LocaleProviderComponent from './components/15.locale-provider';
import Routes from './routes/Routes';
import { persistor, store } from './store';
import { appConnectors } from './web3/connectors';

BigNumber.config({
  ROUNDING_MODE: BigNumber.ROUND_DOWN,
  EXPONENTIAL_AT: [-50, 50],
});

const App: React.FC = () => {
  return (
    <Web3ReactProvider connectors={appConnectors}>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <LocaleProviderComponent>
            <BrowserRouter>
              <Layout>
                <Routes />
              </Layout>
            </BrowserRouter>
          </LocaleProviderComponent>
        </PersistGate>
      </Provider>
    </Web3ReactProvider>
  );
};

export default App;
