import BigNumber from 'bignumber.js';
import { STABLE_COINS } from 'src/constants/exchange';

export const KEY_CODE = {
  E: 69, //e
  PLUS: 189, // +
  PLUS_NUMBER: 107, // +
  SUB_TRACTION: 187, // -
  SUB_TRACTION_NUMBER: 109, // -
  ZERO: 98, // 0
  BACK_SPACE: 8, // Backspace
  ARROW_DOWN: 40, // arrow down
  ARROW_UP: 38, // arrow up
  POINT: 190, // .
  NUMBER_POINT: 110, // .
  COMMA: 188, // ,
};

export const ellipseAddress = (
  address = '',
  maxCharacters = 5,
  maxLastCharacters?: number | undefined,
): string => {
  if (!address) return '';

  return `${address.slice(0, maxCharacters)}...${address.slice(
    -(maxLastCharacters ? maxLastCharacters : maxCharacters),
  )}`;
};

export const decimalCount = (number: any) => {
  const numberAsString = number?.toString();
  if (numberAsString?.includes('.')) {
    return numberAsString?.split('.')[1].length;
  }
  return 0;
};

export const handleOnKeyDownInputNumber = async (
  event: any,
  regex: any = new RegExp(/^(?!$)\d{0,10}(?:\.\d{1,5})?$/),
) => {
  const value = event.target.value?.toString()?.replaceAll(',', '.');
  const pattern = regex;
  if (
    event.keyCode === KEY_CODE.E ||
    event.keyCode === KEY_CODE.PLUS ||
    event.keyCode === KEY_CODE.PLUS_NUMBER ||
    event.keyCode === KEY_CODE.SUB_TRACTION ||
    event.keyCode === KEY_CODE.SUB_TRACTION_NUMBER ||
    event.keyCode === KEY_CODE.ARROW_DOWN ||
    event.keyCode === KEY_CODE.ARROW_UP ||
    (!pattern?.test(Number(value)) && value && event.keyCode !== KEY_CODE.BACK_SPACE) ||
    (!value &&
      (event.keyCode === KEY_CODE.POINT ||
        event.keyCode === KEY_CODE.NUMBER_POINT ||
        event.keyCode === KEY_CODE.COMMA))
  ) {
    event.preventDefault();
  }
};

export const isStableCoin = (coin: any) => {
  return coin?.toUpperCase().includes(STABLE_COINS.USD || STABLE_COINS.USDT || STABLE_COINS.BUSD);
};

export const generatePrecision = (value: string) => {
  if (value?.includes('.')) {
    return value?.split('.')[1].length;
  } else {
    return 0;
  }
};

export const renderLang = (language: string) => {
  if (language && language?.includes('en')) {
    return 'en';
  }
  if (language && language?.includes('vi')) {
    return 'vi';
  } else {
    return language;
  }
};

export function iOS() {
  return (
    ['iPad Simulator', 'iPhone Simulator', 'iPod Simulator', 'iPad', 'iPhone', 'iPod'].includes(
      navigator.platform,
    ) ||
    // iPad on iOS 13 detection
    (navigator.userAgent.includes('Mac') && 'ontouchend' in document)
  );
}

export const formatNumber = (number: string | number, decimals = 18, place?: number) => {
  if (new BigNumber(number).eq(0)) {
    return '0.00';
  }
  return new BigNumber(number || 0).div(Math.pow(10, decimals)).toFormat(place ? place : undefined);
};
