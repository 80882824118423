import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '..';

export const getAccessToken = createSelector(
  (state: RootState) => state.user.accessToken,
  (accessToken) => accessToken,
);

export const getWallet = createSelector(
  (state: RootState) => state.user.wallet,
  (wallet) => wallet,
);
