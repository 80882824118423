import { Col, Row } from 'antd';
import './table-tx.scss';
import { History } from 'src/interfaces/common';
import { useWeb3React } from '@web3-react/core';
import { ellipseAddress, formatNumber } from 'src/helpers';
import moment from 'moment';
import { REACT_APP_BLOCK_EXPLORER_URL } from 'src/web3/constants/envs';

export interface VaultItem {
  totalDeposit: string | number;
  todayReward: string | number;
  todayClaim: string | number;
  totalClaimed: string | number;
}
interface TableTxProps {
  dataSources: History[];
}
const formatDate = `MMM-DD-YYYY hh:mm:ss A`;
const RowRecord = ({ record }: { record: History }) => {
  const { account } = useWeb3React();
  return (
    <>
      <Row className="record">
        <Col span={6} style={{ padding: 16, textAlign: 'center' }}>
          {account && ellipseAddress(account, 8, 4)}
        </Col>
        <Col span={8} style={{ padding: 16, textAlign: 'center' }}>
          {moment(record.createdAt).utc().format(formatDate)}
          {/* Oct-23-2023 07:38:11 AM +UTC */}
        </Col>
        <Col span={4} style={{ padding: 16, textAlign: 'center' }}>
          {`$ ${formatNumber(record.amountClaim || 0 , record.decimals , 4)}`}
        </Col>
        <Col
          span={6}
          style={{ padding: 16, textAlign: 'center' , textDecoration: 'underline' }}
          className="pointer"
          onClick={() =>
            window.open(`${REACT_APP_BLOCK_EXPLORER_URL}/tx/${record.tsxHash}`, 'blank')
          }
        >
          {record.tsxHash.slice(0, 17)}...
        </Col>
      </Row>
    </>
  );
};

const TableTxComponent: React.FC<TableTxProps> = (props: TableTxProps) => {
  const { dataSources } = props;
  return (
    <div className="table-tx">
      <Row className="head flex">
        <Col span={6} style={{ textAlign: 'center' }}>
          wallet Address
        </Col>
        <Col span={8} style={{ textAlign: 'center' }}>
          Claim time
        </Col>
        <Col span={4} style={{ textAlign: 'center' }}>
          Claim Amount
        </Col>
        <Col span={6} style={{ textAlign: 'center' }}>
          Tx Hash
        </Col>
      </Row>
      {dataSources.map((record, index) => (
        <RowRecord record={record} key={index} />
      ))}
    </div>
  );
};

export default TableTxComponent;
