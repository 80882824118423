import { useWeb3React } from '@web3-react/core';
import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from 'src/store';
import { getAccessToken, getWallet } from 'src/store/selectors/user';
import { setIsAuth, setIsAuthChecking } from 'src/store/slices/auth';
import { clearAccessToken, clearWallet } from 'src/store/slices/user';
import { ConnectorKey } from '../connectors';
import { metaMask } from '../connectors/metamask';
import { walletConnect } from '../connectors/walletConnect';

/**
 * Trying eager connect to connectors at first time.
 * @returns `tried` tried eager connect done or not
 */
export function useEagerConnect() {
  const { isActive } = useWeb3React();
  const accessToken = useAppSelector(getAccessToken);
  const dispatch = useAppDispatch();
  const wallet = useAppSelector(getWallet);

  const handleClearData = () => {
    dispatch(clearAccessToken());
    dispatch(clearWallet());
  };

  useEffect(() => {
    if (!isActive) {
      if (wallet === ConnectorKey.walletConnect) {
        walletConnect
          .connectEagerly()
          .then((data) => {
            if (accessToken) {
              dispatch(setIsAuth(true));
            }
          })
          .catch(() => {
            handleClearData();
          });
      } else if (wallet === ConnectorKey.metaMask) {
        metaMask
          .connectEagerly()
          .then((data) => {
            if (accessToken) {
              dispatch(setIsAuth(true));
            }
          })
          .catch(() => {
            handleClearData();
          });
      }
      return;
    }

    // Update `tried` only when isActive was `true`
    dispatch(setIsAuthChecking(true));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isActive, wallet]);
}
