import { useWeb3React } from '@web3-react/core';
import React, { useEffect, useState } from 'react';
import LogoTradeAI from './header/logo-trade-ai.svg';
import Logout from './logout.svg';
import ToastContext from 'src/contexts/toast';
import { ellipseAddress } from 'src/helpers';
import { useConnectWallet, useEagerConnect, useWalletListener } from 'src/web3/hooks';
// import instance from './axios';
import './styles.scss';
import { Collapse, Dropdown, Space, Spin, message } from 'antd';
import { DownOutlined, UpOutlined } from '@ant-design/icons';
import VaultItemComponent from './VaultItem';
import { ethers } from 'ethers';
import { REACT_APP_BSC_RPC_URL } from 'src/web3/constants/envs';
import BigNumber from 'bignumber.js';
import { Metamask, TrustWallet, WalletConnect } from 'src/assets/icons';
import { ConnectorKey } from 'src/web3/connectors';
import useWindowSize from 'src/hooks/useWindowSize';
import { useLogin } from 'src/hooks/useLogin';
import { WEB3_ERROR } from 'src/types';
import { toastMessage } from '../07.toast';
import { MESSAGES } from 'src/constants/messages';
import { useTranslation } from 'react-i18next';
import TableTxComponent from './TableTx';
import instance from './axios';
import { History, UserRewardAPIResponse } from 'src/interfaces/common';

const { Panel } = Collapse;

interface ILayoutProps {
  children?: React.ReactNode;
}

export enum ThemesMode {
  dark = 'dark',
  light = 'light',
}

const LayoutDefault: React.FC<ILayoutProps> = ({ children }) => {
  const { account } = useWeb3React();
  const { isMobile } = useWindowSize();
  const { userLogin } = useLogin();
  const { t } = useTranslation();

  const [balance, setBalance] = useState('0.0000000000000000');
  const [isMetamask, setIsMetamask] = useState<boolean>(true);
  const { disconnectWallet } = useConnectWallet();
  const [userLock, setUserLock] = useState<boolean>(false);
  const [listVault, setListVault] = useState<UserRewardAPIResponse[]>([]);
  const [claimHistories, setClaimHistories] = useState<History[]>([]);
  const [loading, setLoading] = useState(false);

  useEagerConnect();
  useWalletListener();

  useEffect(() => {
    if (account) {
      getBalanceOf();
      getListVault();
      getClaimHistory();
    }
  }, [account]);

  const getListVault = async () => {
    try {
      setLoading(true);
      const res = await instance.get(`get-reward/${account}`);
      if (res.data) {
        setListVault(res.data);
      }
    } catch (error: any) {
      const statusCode = error.response.data.statusCode;
      if (statusCode === 404) {
        message.error('You are not belong to any vaults');
        setListVault([]);
      }
      if (statusCode === 400) {
        message.error(error.response.data.message);
      }
      console.log({ error });
    } finally {
      setLoading(false);
    }
  };

  const getClaimHistory = async () => {
    try {
      const res = await instance.get(`history-claim/${account}`, {
        params: {
          page: 1,
          limit: 100,
        },
      });
      if (res.data?.data) {
        setClaimHistories(res.data.data);
      }
    } catch (error) {}
  };

  const getBalanceOf = async () => {
    const provider = new ethers.providers.JsonRpcProvider(REACT_APP_BSC_RPC_URL);
    const balance = await provider.getBalance(account as string);
    const actualBalance = new BigNumber(balance._hex).div(Math.pow(10, 18)).toString();
    setBalance(actualBalance || '0.0000000000000000');
  };

  const handleDisconnect = () => {
    disconnectWallet();
  };

  const text = `
  A dog is a type of domesticated animal.
  Known for its loyalty and faithfulness,
  it can be found as a welcome guest in many households across the world.
`;

  const menu = (
    <div className="container-list-menu">
      <div className="balance">BNB: {balance}</div>
      <div onClick={handleDisconnect} className="flex between pointer">
        <div className="pointer">Disconnect</div>
        <img src={Logout} alt="icon-logout" />
      </div>
    </div>
  );

  const handleClick = async (connectorKey: ConnectorKey) => {
    try {
      if (isMobile) {
        const metamaskAppDeepLink = `https://metamask.app.link/dapp/${window.location.host}${window.location.pathname}`;
        window.location.assign(metamaskAppDeepLink);
      }

      if (connectorKey === ConnectorKey.metaMask) {
        await userLogin(ConnectorKey.metaMask);
        setUserLock(false);
      } else {
        await userLogin(ConnectorKey.walletConnect);
        setUserLock(false);
      }
    } catch (error: any) {
      // handleError(error, isMetamask ? ConnectorKey.metaMask : ConnectorKey.walletConnect);
    }
  };

  const handleUserReject = (error: WEB3_ERROR) => {
    toastMessage.error(t(MESSAGES.MC13));
  };

  const handleError = (error: WEB3_ERROR, connectorKey: ConnectorKey) => {
    console.log('error', error);
    switch (error.type) {
      case 'user_reject':
        handleUserReject(error);
        break;
      case 'un_support_chain':
        toastMessage.error(t(MESSAGES.MC14), t(MESSAGES.MC15));
        break;
      case 'no_eth_provider':
        setIsMetamask(false);
        break;
      default:
        toastMessage.error(MESSAGES.MC3);
    }
  };

  const isMetaMaskInstalled = () => {
    return Boolean((window as any).ethereum && (window as any).ethereum.isMetaMask);
  };

  const onChange = (key: string | string[]) => {
    console.log(key);
  };

  return (
    <div id="layout">
      <ToastContext />
      <div className="header">
        <div className="icon-wrapper-desktop">
          <a href="https://www.tradeai.solutions/" target="_blank" rel="noreferrer">
            <img src={LogoTradeAI} alt="logo-trade-ai" />
          </a>
        </div>
        {/* {!account && (
          <ButtonConnect onClick={() => setIsOpenConnectWalletModal(true)}>
            CONNECT WALLET
          </ButtonConnect>
        )} */}
        {account && (
          <Dropdown overlay={menu} overlayClassName={'dropdown-wrapper'} placement="bottomRight">
            <a onClick={(e) => e.preventDefault()}>
              <Space className="hover-address">
                {ellipseAddress(account, 8, 8)}
                <DownOutlined />
              </Space>
            </a>
          </Dropdown>
        )}
      </div>
      {!account && (
        <>
          <div className="list-icon">
            <img src={Metamask} onClick={() => handleClick(ConnectorKey.metaMask)} />
            <img
              src={TrustWallet}
              style={{ margin: '0 40px' }}
              onClick={() => handleClick(ConnectorKey.metaMask)}
            />
            <img
              src={WalletConnect}
              onClick={() => {
                handleClick(ConnectorKey.walletConnect);
              }}
            />
          </div>
          {(!isMetaMaskInstalled() || !isMetamask || userLock) && (
            <div className="error-message-metamask" style={{ textAlign: 'center', marginTop: 10 }}>
              {t(MESSAGES.MC12)}
            </div>
          )}
        </>
      )}

      <>
        {loading ? (
          <div
            style={{
              height: '89vh',
              position: 'relative',
              textAlign: 'center',
            }}
          >
            <Space
              size="middle"
              style={{
                position: 'absolute',
                top: '40%',
              }}
            >
              <Spin size="large" />
            </Space>
          </div>
        ) : (
          <>
            {account && listVault.length > 0 && (
              <>
                <div className="container">
                  {listVault.map(
                    (item, index) =>
                      item.userOfVault && (
                        <VaultItemComponent
                          item={item}
                          title={`vault ${account ? index : ''}`}
                          key={index}
                          onReload={async () => {
                            console.log('RELOAD');
                            await getListVault();
                            await getClaimHistory();
                          }}
                        />
                      ),
                  )}
                </div>
                <Collapse
                  onChange={onChange}
                  className="collapse-trade-ai"
                  expandIcon={({ isActive }) =>
                    !isActive ? (
                      <DownOutlined style={{ fontSize: 18 }} />
                    ) : (
                      <UpOutlined style={{ fontSize: 18 }} />
                    )
                  }
                >
                  <Panel header={<span className="label">Claim History</span>} key="1">
                    <TableTxComponent dataSources={claimHistories} />
                  </Panel>
                </Collapse>
                <div style={{ height: 60 }}></div>
              </>
            )}
          </>
        )}
      </>
    </div>
  );
};

export default LayoutDefault;
