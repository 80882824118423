import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type AuthStates = {
  accessToken: string | null;
  currentAccount: string | null;
  balance: string | null;
  wallet: string | null;
};

const initialState: AuthStates = {
  accessToken: null,
  currentAccount: null,
  balance: null,
  wallet: null,
};

// slice
export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setAccessToken: (state, actions: PayloadAction<string | null>) => {
      state.accessToken = actions.payload;
    },
    clearAccessToken: (state) => {
      state.accessToken = null;
    },
    setCurrentAccount: (state, actions: PayloadAction<string | null>) => {
      state.currentAccount = actions.payload;
    },
    setWallet: (state, actions: PayloadAction<string | null>) => {
      state.wallet = actions.payload;
    },
    clearWallet: (state) => {
      state.wallet = null;
    },
    clearCurrentAccount: (state) => {
      state.currentAccount = null;
    },
    setAccountBalance: (state, actions: PayloadAction<string>) => {
      state.balance = actions.payload;
    },
    clearAccountBalance: (state) => {
      state.balance = null;
    },
  },
});

// actions
export const {
  setAccessToken,
  setCurrentAccount,
  clearAccountBalance,
  setAccountBalance,
  clearCurrentAccount,
  setWallet,
  clearWallet,
  clearAccessToken
} = userSlice.actions;

// reducer
export const userReducer = userSlice.reducer;
