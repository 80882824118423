import { Newtwork } from '../types';
import {
  REACT_APP_BLOCK_EXPLORER_URL,
  REACT_APP_BSC_CHAIN_ID_HEX,
  REACT_APP_BSC_RPC_URL,
  REACT_APP_CHAIN_ID,
  REACT_APP_NETWORK_NAME,
} from './envs';

export const SUPPORTED_NETWORKS: { [key: string]: Newtwork } = {
  [REACT_APP_CHAIN_ID as string]: {
    chainId: Number(REACT_APP_CHAIN_ID),
    chainIdHex: REACT_APP_BSC_CHAIN_ID_HEX as string,
    chainName: REACT_APP_NETWORK_NAME as string,
    nativeCurrency: { name: 'ETH', symbol: 'ETH', decimals: 18 },
    blockExplorerUrls: [REACT_APP_BLOCK_EXPLORER_URL as string],
    rpcUrls: [REACT_APP_BSC_RPC_URL as string],
  },
};
