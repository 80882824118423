import { ContractInterface } from 'ethers';

export default class ClassVaultContract {
  instance: any;

  constructor(instance: ContractInterface) {
    this.instance = instance;
  }

  claimRewards = (amount: string, signature: string) => {
    return this.instance.claimRewards(amount, signature);
  };

  userClaimed = (address :string) => {
    return this.instance.userClaimed(address);
  }
}
