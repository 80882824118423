const {
  REACT_APP_MESSAGES_SIGN,
  REACT_APP_CHAIN_ID,
  REACT_APP_NETWORK_NAME,
  REACT_APP_BLOCK_EXPLORER_URL,
  REACT_APP_BSC_RPC_URL,
  REACT_APP_BSC_CHAIN_ID_HEX,
  REACT_APP_PROJECT_ID,
  REACT_APP_MINT_CONTRACT,
  REACT_APP_PUBLIC_KEY,
  REACT_APP_API_BASE_URL,
  REACT_APP_START_PRE_SALE_TIME,
} = process.env;

export {
  REACT_APP_MESSAGES_SIGN,
  REACT_APP_CHAIN_ID,
  REACT_APP_NETWORK_NAME,
  REACT_APP_BLOCK_EXPLORER_URL,
  REACT_APP_BSC_RPC_URL,
  REACT_APP_BSC_CHAIN_ID_HEX,
  REACT_APP_PROJECT_ID,
  REACT_APP_MINT_CONTRACT,
  REACT_APP_PUBLIC_KEY,
  REACT_APP_API_BASE_URL,
  REACT_APP_START_PRE_SALE_TIME,
};
