import { UserRewardAPIResponse } from 'src/interfaces/common';
import ButtonContained from '../02.buttons/ButtonContained';
import './vault-item.scss';
import BigNumber from 'bignumber.js';
import { useVault } from 'src/hooks/useVault';
import { message } from 'antd';
import { useState } from 'react';
import { formatNumber } from 'src/helpers';

interface VaultItemProps {
  item: UserRewardAPIResponse;
  title: string;
  onReload: () => void;
}

const VaultItemComponent: React.FC<VaultItemProps> = (props: VaultItemProps) => {
  const { title, item, onReload } = props;
  const { contractAddres, amountReward, signUser } = item;
  const { claimRewards } = useVault(contractAddres);
  const [loading, setLoading] = useState(false);

  const handleClaimRewards = async () => {
    try {
      setLoading(true);
      console.log({ amountReward, signUser });
      await claimRewards(amountReward, signUser);
      message.success('Claimed!');
      onReload();
    } catch (error) {
      console.error(error);
      message.error('Claim failed');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="vault-item">
      <div className="title">
        <div>{title}</div>
        <div>{`$ ${formatNumber(item.totalAmountAdminDeposit || 0, Number(item.decimals), 2)}`}</div>
      </div>
      <div className="item flex between">
        <div>Total Deposit: </div>
        <div>{`$ ${new BigNumber(item.totalDepositByUser || 0).toFormat(2)}`}</div>
      </div>
      <div className="item flex between">
        <div>Your allocation: </div>
        <div>{`${new BigNumber(item.allocation).multipliedBy(100).toFormat(4)} %`}</div>
      </div>
      <div className="item flex between">
        <div>Available Reward: </div>
        <div>{`$ ${formatNumber(item.amountReward || 0,  Number(item.decimals), 4)}`}</div>
      </div>
      <div className="item flex between">
        <div>Today’s Claim: </div>
        <div>{`$ ${formatNumber(item.todayClaim || 0,  Number(item.decimals), 4)}`}</div>
      </div>
      <div className="item flex between">
        <div>Total Claimed: </div>
        <div>{`$ ${formatNumber(item.totalUserClaimed || 0,  Number(item.decimals), 4)}`}</div>
      </div>
      <div className="fullWidth center">
        <ButtonContained loading={loading} onClick={handleClaimRewards} className="claim-button">
          Claim
        </ButtonContained>
      </div>
    </div>
  );
};

export default VaultItemComponent;
