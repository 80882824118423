import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type AuthStates = {
  isAuthChecking: boolean;
  isAuth: boolean;
};

const initialState: AuthStates = {
  isAuthChecking: true,
  isAuth: false,
};

// slice
export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setIsAuthChecking: (state, actions: PayloadAction<boolean>) => {
      state.isAuthChecking = actions.payload;
    },
    setIsAuth: (state, actions: PayloadAction<boolean>) => {
      state.isAuth = actions.payload;
    },
  },
});

// actions
export const { setIsAuthChecking, setIsAuth } = authSlice.actions;

// reducer
export const authReducer = authSlice.reducer;
