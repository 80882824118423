export const PATHS = {
  default: '/',
  exchange: '/exchange',
  iao: '/iao',
  marketOverview: '/market-overview',
  walletAnalytics: '/wallet-analytics',
  myOrderStatistics: '/my-order-statistics',
  notFound: '/404',
  aboutUs: '/about-us',
  contactUs: '/contact-Us',
  affiliates: 'affiliates',
  becomeAffiliates: 'become-affiliates',
  userGuides: 'user-guides',
  myTier: '/my-tier',
  allTier: '/all-tier',
};
