import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState: string = 'Light';

// slice
export const themeSlice = createSlice({
  name: 'theme',
  initialState,
  reducers: {
    setTheme: (state, actions: PayloadAction<string>) => (state = actions.payload),
  },
});

// actions
export const { setTheme } = themeSlice.actions;

// reducer
export const themeReducer = themeSlice.reducer;
