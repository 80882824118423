import ClassVaultContract from 'src/helpers/classVaultContract';
import { useVaultClaimContract } from 'src/web3/contracts/useVaultClaim';

export const useVault = (contractAddress: string) => {
  const initVaultContract = useVaultClaimContract(contractAddress);
  console.log({ initVaultContract });
  const contract = new ClassVaultContract(initVaultContract);

  const claimRewards = async (amount: string, signature: string) => {
    const tx = await contract.claimRewards(String(amount), signature);
    await tx.wait();
  };

  const userClaimed = async (address: string) => {
    return contract.userClaimed(address);
  };

  return {
    claimRewards,
    userClaimed,
  };
};
